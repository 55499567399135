<!-- 新增或修改配变监测终端 -->
<template>
<div>
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="mini"
             :disabled="type === 'detail'" label-position="top">
    <el-row :gutter="24">
        <el-col :span="12">
            <el-form-item label="监测点名称" prop="name">
                <el-input v-model="dataForm.name" placeholder="监测点名称"></el-input>
            </el-form-item>
        </el-col>
        <el-col :span="12">
            <el-form-item label="监测点地址" prop="address">
                <el-input v-model="dataForm.address" placeholder="监测点地址"></el-input>
            </el-form-item>
        </el-col>
        <el-col :span="12">
            <el-form-item label="仪表编号" prop="number">
                <el-input v-model="dataForm.number" placeholder="仪表编号"></el-input>
            </el-form-item>
        </el-col>
        <el-col :span="12">
            <el-form-item label="设备状态" prop="state">
                <el-select v-model="dataForm.state" placeholder="情选择状态">
                    <el-option
                        v-for="item in stateList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.code">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-col>
    </el-row>
    </el-form>
  </div>
</template>

<script>
export default {

    props: {
        id: {
            type: Number,
            default: null,
        },
        type: {
            type: String,
            default: 'add',
        },
    },
    data() {
        return {
            stateList: [], //状态列表
            dataForm: {
                name: '',
                address: '',
                number: '',
                state: '',
            },
            uploadRes: null,
            dataRule: {
                name: [
                    {required: true, message: '监测点名称不能为空', trigger: 'blur'}
                ],
                address: [
                    {required: true, message: '监测点地址不能为空', trigger: 'blur'}
                ],
                number: [
                    {required: true, message: '仪表编号不能为空', trigger: 'blur'}
                ],
                state: [
                    {required: true, message: '监测点设备状态不能为空', trigger: 'blur'}
                ]
            }
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            if (this.id) {
                this.$client.getTerminalEquipmentsInfo(this.id).then(res => {
                    this.dataForm = res.data;
                });
            }
            //获取数据字典
            let code = 'equipment_state';
            this.$client.listDictionaryChildrenArr(code).then(res => {
                this.stateList = res.data.equipment_state;
            });
        },
        // 表单提交
        submitHandle(callback) {
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    if (this.id) {
                        this.$client.updateTerminalEquipments(this.id, this.dataForm).then(res => {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500
                            });
                            callback();
                        });
                    } else {
                        this.$client.saveTerminalEquipments(this.dataForm).then(() => {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500
                            });
                            callback();
                        });
                    }
                }
            });
        }
    }
};
</script>
