<!-- 配变监测终端维护 -->
<template>
    <div class="ui-layout-container ui-height-100">
        <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
            <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
                <div class="ui-layout-content--full ui-form-actions" >
                    <el-button type="primary" @click="addHandle" style="border-radius: 4px">新增</el-button>
                </div>
                <div class="ui-layout-content--full ui-layout-flex--1">
                    <ex-search-table-pagination
                        style="height: 380px"
                        ref="searchTable"
                        :fetch="$client.getTerminalEquipmentsList"
                        :columns="columns"
                        :formOptions="formOptions"
                        :searchWhenSortChange="true"
                    >
                        <template slot="append">
                            <el-table-column
                                align="center"
                                label="操作"
                                type="action"
                                width="260"
                                fixed="right"
                            >
                                <template slot-scope="scope">
                                    <el-button type="primary" @click="detailHandle(scope.row)">详情</el-button>
                                    <el-button type="primary" @click="updateHandle(scope.row)">修改</el-button>
                                    <el-button type="primary" @click="delHandle(scope.row)">删除</el-button>
                                </template>
                            </el-table-column>
                        </template>
                    </ex-search-table-pagination>
                </div>
            </div>
        </div>
        <div class="ui-layout-content--full">
            <!-- 弹窗, 新增 / 修改 -->
            <el-dialog
                v-bind="readDialog"
                width="760px"
                :title="readDialog.title"
                v-if="readDialog.visible"
                :visible.sync="readDialog.visible"
                :modal-append-to-body="true"
                :close-on-click-modal="false"
                :append-to-body="true"
                :show-close="true"
            >
                <AddOrUpdate ref="addOrUpdate" :id="readDialog.currentId" :type="readDialog.type"></AddOrUpdate>
                <span v-if="readDialog.type !== 'detail'" slot="footer" class="dialog-footer">
                    <el-button @click="readDialog.visible = false" size="mini">取消</el-button>
                    <el-button type="primary" @click="submitHandbookHandle" size="mini">确定</el-button>
                </span>
                <span v-if="readDialog.type === 'detail'" slot="footer" class="dialog-footer">
                    <el-button @click="readDialog.visible = false" size="mini">关闭</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import AddOrUpdate from './create';
export default {
    name: 'equipmentList',
    components: {AddOrUpdate},
    comments: {AddOrUpdate},
    data() {
        return {
            dataList: [], //列表数据
            stateList: [], //设备状态
            readDialog: {
                visible: false,
                title: '',
                currentId: '',
                type: '',
            }
        };
    },

    computed: {
        columns() {
            return [
                {
                    prop: 'name',
                    label: '监测点名称',
                    fixed: true,
                },
                {
                    prop: 'address',
                    label: '监测点地址',
                },
                {
                    prop: 'number',
                    label: '仪表编号',
                },
                {
                    prop: 'state',
                    label: '状态',
                    formatter: (row, column, cellValue) => {
                        if (row.state && this.stateList) {
                            let data = this.stateList.filter(item => item.value === row.state)[0];
                            return data.name;
                        }
                    },
                },
            ];
        },
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'name',
                        placeholder: '监测点名称'
                    },
                ],
            };
        },
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            //获取数据字典
            let code = 'equipment_state';
            this.$client.listDictionaryChildrenArr(code).then(res => {
                this.stateList = res.data.equipment_state;
            });
        },
        submitHandbookHandle() {
            this.$refs.addOrUpdate.submitHandle((rest) => {
                this.$refs.searchTable.searchHandler();
                this.readDialog.visible = false;
            });
        },
        // 新增
        addHandle() {
            this.readDialog = {
                title: '新增',
                currentId: 0,
                visible: true,
                type: 'add',
            };
        },
        // 修改
        updateHandle(row) {
            this.readDialog = {
                title: '修改',
                currentId: row.id,
                visible: true,
                type: 'update',
            };
        },
        // 详情
        detailHandle(row) {
            this.readDialog = {
                title: '详情',
                currentId: row.id,
                visible: true,
                type: 'detail',
            };
        },
        delHandle(row) {
            this.$confirm(`确定删除选中的数据`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                if (row.id) {
                    let ids = [row.id];
                    this.$client.delTerminalEquipments(ids).then((res) => {
                        if (res.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 2000,
                            });
                        }
                        this.$refs.searchTable.searchHandler();
                    });
                } else {
                    this.$message({
                        message: '操作失败，请联系系统管理员',
                        type: 'error',
                    });
                    return;
                }
            });
        },
    },
};
</script>

<style scoped>

</style>
