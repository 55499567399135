<!-- 配变监测终端监测台账 -->
<template>
    <div class="ui-layout-container ui-height-100">
        <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
            <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
                <div class="ui-layout-content--full ui-form-actions" style="margin: 20px 20px 0px 20px">
                    <el-button type="primary" @click="dataExport" style="border-radius: 4px">导出</el-button>
                    <el-button type="primary" @click="showEquipmentList" style="border-radius: 4px">配变终端维护</el-button>
                </div>
                <div class="ui-layout-content--full ui-layout-flex--1" style="margin: 0px 20px 20px 20px">
                    <ex-search-table-pagination
                        style="height: 100%"
                        ref="searchTable"
                        :data="dataList"
                        :fetch="getOneDataList"
                        :columns="columns"
                        :formOptions="formOptions"
                        :searchWhenSortChange="true"
                        pageSizeKey="pageSize"
                        pageIndexKey="pageNumber"
                        listField="items"
                        totalField="totalItemCount"
                    >
                    </ex-search-table-pagination>
                </div>
            </div>
        </div>

        <div class="ui-layout-content--full">
            <!-- 弹窗, 新增 / 修改 -->
            <el-dialog
                v-bind="readDialog"
                width="1060px"
                height
                title="配变终端维护"
                v-if="readDialog.visible"
                :visible.sync="readDialog.visible"
                :modal-append-to-body="true"
                :close-on-click-modal="false"
                :append-to-body="true"
                :show-close="true"
            >
                <equipment-list></equipment-list>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="readDialog.visible = false" size="mini">关闭</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import * as dayjs from 'dayjs';
import EquipmentList from './equipmentList';
import {fileDownload} from '@/utils';
export default {
    components: {EquipmentList},
    data() {
        return {
            dataList: [], //列表数据
            equipmentList: [], //终端设备信息
            readDialog: {
                visible: false,
            }
        };
    },
    computed: {
        columns() {
            return [
                {
                    prop: 'name',
                    label: '监测点名称',
                    fixed: true,
                },
                {
                    prop: 'address',
                    label: '监测点地址',
                },
                {
                    prop: 'number',
                    label: '仪器编号',
                },
                {
                    prop: 'RealTimeData_177.电压监测时间',
                    label: '电压监测时间',
                },
                {
                    prop: 'RealTimeData_177.电压合格率',
                    label: '电压合格率%',
                },
                {
                    prop: 'RealTimeData_177.电压超限率',
                    label: '电压超限率',
                },
                {
                    prop: 'RealTimeData_177.电压超上限时间',
                    label: '电压超上限时间',
                },
                {
                    prop: 'RealTimeData_177.电压超下限时间',
                    label: '电压超下限时间',
                },
                {
                    prop: 'RealTimeData_177.最高电压',
                    label: '最高电压',
                },
                {
                    prop: 'RealTimeData_177.最高电压出现时间',
                    label: '最高电压出现时间',
                    formatter(row) {
                        if (row['RealTimeData_177.最高电压出现时间']) {
                            return dayjs(row['RealTimeData_177.最高电压出现时间'], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD hh:mm');
                        }
                    }
                },
                {
                    prop: 'RealTimeData_177.最低电压',
                    label: '最低电压',
                },
                {
                    prop: 'RealTimeData_177.最低电压出现时间',
                    label: '最低电压出现时间',
                    formatter(row) {
                        if (row['RealTimeData_177.最低电压出现时间']) {
                            return dayjs(row['RealTimeData_177.最低电压出现时间'], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD hh:mm');
                        }
                    }
                },
                {
                    prop: 'RealTimeData_25.A相电流',
                    label: 'A相电流',
                },
                {
                    prop: 'RealTimeData_25.B相电流',
                    label: 'B相电流',
                },
                {
                    prop: 'RealTimeData_25.C相电流',
                    label: 'C相电流',
                },
                {
                    prop: 'RealTimeData_25.A相电压',
                    label: 'A相电压',
                },
                {
                    prop: 'RealTimeData_25.B相电压',
                    label: 'B相电压',
                },
                {
                    prop: 'RealTimeData_25.C相电压',
                    label: 'C相电压',
                },
                {
                    prop: 'RealTimeData_178.A相电压合格率',
                    label: 'A相电压合格率',
                },
                {
                    prop: 'RealTimeData_178.A相电压超限率',
                    label: 'A相电压超限率',
                },
                {
                    prop: 'RealTimeData_178.A相电压超上限时间',
                    label: 'A相电压超上限时间',
                },
                {
                    prop: 'RealTimeData_178.A相电压超下限时间',
                    label: 'A相电压超下限时间',
                },
                {
                    prop: 'RealTimeData_178.A相最高电压',
                    label: 'A相最高电压',
                },
                {
                    prop: 'RealTimeData_178.A相最高电压出现时间',
                    label: 'A相最高电压出现时间',
                    formatter(row) {
                        if (row['RealTimeData_178.A相最高电压出现时间']) {
                            return dayjs(row['RealTimeData_178.A相最高电压出现时间'], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD hh:mm');
                        }
                    }
                },
                {
                    prop: 'RealTimeData_178.A相最低电压',
                    label: 'A相最低电压',
                },
                {
                    prop: 'RealTimeData_178.A相最低电压出现时间',
                    label: 'A相最低电压出现时间',
                    formatter(row) {
                        if (row['RealTimeData_178.A相最低电压出现时间']) {
                            return dayjs(row['RealTimeData_178.A相最低电压出现时间'], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD hh:mm');
                        }
                    }
                },
                {
                    prop: 'RealTimeData_179.B相电压合格率',
                    label: 'B相电压合格率',
                },
                {
                    prop: 'RealTimeData_179.B相电压超限率',
                    label: 'B相电压超限率',
                },
                {
                    prop: 'RealTimeData_179.B相电压超上限时间',
                    label: 'B相电压超上限时间',
                },
                {
                    prop: 'RealTimeData_179.B相电压超下限时间',
                    label: 'B相电压超下限时间',
                },
                {
                    prop: 'RealTimeData_179.B相最高电压',
                    label: 'B相最高电压',
                },
                {
                    prop: 'RealTimeData_179.B相最高电压出现时间',
                    label: 'B相最高电压出现时间',
                    formatter(row) {
                        if (row['RealTimeData_179.B相最高电压出现时间']) {
                            return dayjs(row['RealTimeData_179.B相最高电压出现时间'], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD hh:mm');
                        }
                    }
                },
                {
                    prop: 'RealTimeData_179.B相最低电压',
                    label: 'B相最低电压',
                },
                {
                    prop: 'RealTimeData_179.B相最低电压出现时间',
                    label: 'B相最低电压出现时间',
                    formatter(row) {
                        if (row['RealTimeData_179.B相最低电压出现时间']) {
                            return dayjs(row['RealTimeData_179.B相最低电压出现时间'], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD hh:mm');
                        }
                    }
                },
                {
                    prop: 'RealTimeData_180.C相电压超限率',
                    label: 'C相电压超限率',
                },
                {
                    prop: 'RealTimeData_180.C相电压超上限时间',
                    label: 'C相电压超上限时间',
                },
                {
                    prop: 'RealTimeData_180.C相电压超下限时间',
                    label: 'C相电压超下限时间',
                },
                {
                    prop: 'RealTimeData_180.C相最高电压',
                    label: 'C相最高电压',
                },
                {
                    prop: 'RealTimeData_180.C相最高电压出现时间',
                    label: 'C相最高电压出现时间',
                    formatter(row) {
                        if (row['RealTimeData_180.C相最高电压出现时间']) {
                            return dayjs(row['RealTimeData_180.C相最高电压出现时间'], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD hh:mm');
                        }
                    }
                },
                {
                    prop: 'RealTimeData_180.C相最低电压',
                    label: 'C相最低电压',
                },
                {
                    prop: 'RealTimeData_180.C相最低电压出现时间',
                    label: 'C相最低电压出现时间',
                    formatter(row) {
                        if (row['RealTimeData_180.C相最低电压出现时间']) {
                            return dayjs(row['RealTimeData_180.C相最低电压出现时间'], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD hh:mm');
                        }
                    }
                },

                {
                    prop: 'HistoryData_28.电流不平衡度越限日累计时间',
                    label: '电流不平衡度越限日累计时间',
                },
                {
                    prop: 'HistoryData_28.电流不平衡最大值',
                    label: '电流不平衡最大值',
                },
                {
                    prop: 'HistoryData_28.电流不平衡最大值发生时间',
                    label: '电流不平衡最大值发生时间',
                    formatter(row) {
                        if (row['RealTimeData_180.C相最低电压出现时间']) {
                            return dayjs(row['HistoryData_28.电流不平衡最大值发生时间'], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD hh:mm');
                        }
                    }
                },
                {
                    prop: 'HistoryData_28.电压不平衡度越限日累计时间',
                    label: '电压不平衡度越限日累计时间',
                }, {
                    prop: 'HistoryData_28.电压不平衡最大值',
                    label: '电压不平衡最大值',
                },
                {
                    prop: 'HistoryData_28.电压不平衡最大值发生时间',
                    label: '电压不平衡最大值发生时间',
                    formatter(row) {
                        if (row['HistoryData_28.电压不平衡最大值发生时间']) {
                            return dayjs(row['HistoryData_28.电压不平衡最大值发生时间'], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD hh:mm');
                        }
                    }
                },
                {
                    prop: 'RealTimeData_58.A相总谐波电压含有率',
                    label: 'A相总谐波电压含有率',
                },
                {
                    prop: 'RealTimeData_58.B相总谐波电压含有率',
                    label: 'B相总谐波电压含有率',
                },
                {
                    prop: 'RealTimeData_58.C相总谐波电压含有率',
                    label: 'C相总谐波电压含有率',
                }, {
                    prop: 'RealTimeData_25.总有功功率',
                    label: '总有功功率',
                }, {
                    prop: 'RealTimeData_25.总无功功率',
                    label: '总无功功率',
                },
                {
                    prop: 'RealTimeData_49.Uab/Ua相位角',
                    label: 'Uab/Ua相位角',
                },
                {
                    prop: 'RealTimeData_49.Ub相位角',
                    label: 'Ub相位角',
                },
                {
                    prop: 'RealTimeData_49.Ucb/Uc相位角',
                    label: 'Ucb/Uc相位角',
                },
                {
                    prop: 'RealTimeData_49.Ia相位角',
                    label: 'Ia相位角',
                },
                {
                    prop: 'RealTimeData_49.Ib相位角',
                    label: 'Ib相位角',
                },
                {
                    prop: 'RealTimeData_49.Ic相位角',
                    label: 'Ic相位角',
                },
                {
                    prop: 'Time',
                    label: '最后心跳时间',
                    formatter(row) {
                        if (row.Time) {
                            return dayjs(row.Time, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD hh:mm');
                        }
                    }
                },
            ];
        },
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'name',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '监测点名称',
                        options: this.equipmentList,
                        valueKey: 'number',
                        labelKey: 'name',
                    },
                    {
                        prop: 'start',
                        itemType: 'datetime',
                        clearable: true,
                        filterable: true,
                        placeholder: '开始时间',
                    },
                    {
                        prop: 'end',
                        itemType: 'datetime',
                        clearable: true,
                        filterable: true,
                        placeholder: '结束时间',
                    },
                    /*{
                        prop: 'timeHorizon',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '时间间隔',
                        options: [
                            {code: '1d', value: '近一天'},
                            {code: '2d', value: '近二天'},
                            {code: '3d', value: '近三天'},
                            {code: '4d', value: '近四天'},
                            {code: '5d', value: '近五天'},
                            {code: '6d', value: '近六天'},
                            {code: '7d', value: '近七天'},
                        ],
                        valueKey: 'code',
                        labelKey: 'value',
                    },
                    {
                        prop: 'timeRange',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '采集周期',
                        options: [
                            {code: '1h', value: '1小时'},
                            {code: '30m', value: '30分钟'},
                            {code: '15m', value: '15分钟'},
                        ],
                        valueKey: 'code',
                        labelKey: 'value',
                    },*/
                ],
            };
        },
    },
    created() {
        this.init();
    },
    methods: {
        //导出
        dataExport() {
            let exportData = [];
            this.dataList.forEach(item => {
                let data = {};

                data.name = item.name;
                data.address = item.address;
                data.number = item.number;
                data.Time = dayjs(item.Time, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD hh:mm');
                data.o1 = item['RealTimeData_177.电压监测时间'];
                data.o2 = item['RealTimeData_177.电压合格率'];
                data.o3 = item['RealTimeData_177.电压超限率'];
                data.o4 = item['RealTimeData_177.电压超上限时间'];
                data.o5 = item['RealTimeData_177.电压超下限时间'];
                data.o6 = item['RealTimeData_177.最高电压'];
                data.o7 = dayjs(item['RealTimeData_177.最高电压出现时间'], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD hh:mm');
                data.o8 = item['RealTimeData_177.最低电压'];
                data.o9 = dayjs(item['RealTimeData_177.最低电压出现时间'], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD hh:mm');
                data.o10 = item['RealTimeData_25.A相电流'];
                data.o11 = item['RealTimeData_25.B相电流'];
                data.o12 = item['RealTimeData_25.C相电流'];
                data.o13 = item['RealTimeData_25.A相电压'];
                data.o14 = item['RealTimeData_25.B相电压'];
                data.o15 = item['RealTimeData_25.C相电压'];
                data.o16 = item['RealTimeData_178.A相电压合格率'];
                data.o17 = item['RealTimeData_178.A相电压超限率'];
                data.o18 = item['RealTimeData_178.A相电压超上限时间'];
                data.o19 = item['RealTimeData_178.A相电压超下限时间'];
                data.o20 = item['RealTimeData_178.A相最高电压'];
                data.o21 = dayjs(item['RealTimeData_178.A相最高电压出现时间'], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD hh:mm');
                data.o22 = item['RealTimeData_178.A相最低电压'];
                data.o23 = dayjs(item['RealTimeData_178.A相最低电压出现时间'], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD hh:mm');
                data.o24 = item['RealTimeData_179.B相电压合格率'];
                data.o25 = item['RealTimeData_179.B相电压超限率'];
                data.o26 = item['RealTimeData_179.B相电压超上限时间'];
                data.o27 = item['RealTimeData_179.B相电压超下限时间'];
                data.o28 = item['RealTimeData_179.B相最高电压'];
                data.o29 = dayjs(item['RealTimeData_179.B相最高电压出现时间'], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD hh:mm');
                data.o30 = item['RealTimeData_179.B相最低电压'];
                data.o31 = dayjs(item['RealTimeData_179.B相最低电压出现时间'], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD hh:mm');
                data.o32 = item['RealTimeData_180.C相电压超限率'];
                data.o33 = item['RealTimeData_180.C相电压超上限时间'];
                data.o34 = item['RealTimeData_180.C相电压超下限时间'];
                data.o35 = item['RealTimeData_180.C相最高电压'];
                data.o36 = dayjs(item['RealTimeData_180.C相最高电压出现时间'], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD hh:mm');
                data.o37 = item['RealTimeData_180.C相最低电压'];
                data.o38 = dayjs(item['RealTimeData_180.C相最低电压出现时间'], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD hh:mm');
                data.o39 = item['HistoryData_28.电流不平衡度越限日累计时间'];
                data.o40 = item['HistoryData_28.电流不平衡最大值'];
                data.o41 = dayjs(item['HistoryData_28.电流不平衡最大值发生时间'], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD hh:mm');
                data.o42 = item['HistoryData_28.电压不平衡度越限日累计时间'];
                data.o43 = item['HistoryData_28.电压不平衡最大值'];
                data.o44 = dayjs(item['HistoryData_28.电压不平衡最大值发生时间'], 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('YYYY-MM-DD hh:mm');
                /*data.o45 = item['总畸变电压含有率上限'];
                data.o46 = item['奇次谐波电压含有率上限'];
                data.o47 = item['偶次谐波电压含有率上限'];*/
                data.o48 = item['RealTimeData_58.A相总谐波电压含有率'];
                data.o49 = item['RealTimeData_58.B相总谐波电压含有率'];
                data.o50 = item['RealTimeData_58.C相总谐波电压含有率'];
                data.o51 = item['RealTimeData_25.总有功功率'];
                data.o52 = item['RealTimeData_25.总无功功率'];
                data.o53 = item['RealTimeData_49.Uab/Ua相位角'];
                data.o54 = item['RealTimeData_49.Ub相位角'];
                data.o55 = item['RealTimeData_49.Ucb/Uc相位角'];
                data.o56 = item['RealTimeData_49.Ia相位角'];
                data.o57 = item['RealTimeData_49.Ib相位角'];
                data.o58 = item['RealTimeData_49.Ic相位角'];
                exportData.push(data);
            });
            fileDownload('/business/terminalEquipments/download', {method: 'POST',
                data: {data: exportData}});
        },
        showEquipmentList() {
            this.readDialog = {
                visible: true,
            };
        },
        async init () {
            this.getEquipments();
        },
        //获取所有设备信息
        getEquipments() {
            this.$client.allTerminalEquipments().then(res => {
                this.equipmentList = res.data;
            });
        },
        getOneDataList(val) {
            if (!val.name && !val.start && !val.end) {
                //条件不填时默认查询24小时内最新的一批数据
                let end = dayjs();
                let start = dayjs(end).subtract(24 * 60, 'minute');
                let startTime = dayjs(start).utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                let endTime = dayjs(end).utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                let params = {
                    size: 500,
                    sort: [
                        {
                            Time: {
                                order: 'desc',
                                unmapped_type: 'boolean'
                            }
                        }
                    ],
                    version: true,
                    fields: [
                        {
                            field: '*',
                            include_unmapped: 'true'
                        },
                        {
                            field: 'Time',
                            format: 'strict_date_optional_time'
                        }
                    ],
                    script_fields: {

                    },
                    stored_fields: [
                        '*'
                    ],
                    runtime_mappings: {

                    },
                    _source: false,
                    query: {
                        bool: {
                            must: [

                            ],
                            filter: [
                                {
                                    match_all: {

                                    }
                                },
                                {
                                    range: {
                                        Time: {
                                            gte: startTime,
                                            lte: endTime,
                                            format: 'strict_date_optional_time'
                                        }
                                    }
                                }
                            ],
                            should: [

                            ],
                            must_not: [

                            ]
                        }
                    }
                };

                this.$client.terminalEquipmentList(params).then(res => {
                    let dataList = [];
                    let data = res.data.hits.hits;
                    this.equipmentList.forEach(i => {
                        let item = data.filter(item => item.fields.Identity[0] === i.number)[0];
                        if (item?.fields) {
                            for (let key in item.fields) {
                                item.fields[key] = item.fields[key][0];
                                item.fields.name = i.name;
                                item.fields.address = i.address;
                                item.fields.number = i.number;
                            }
                            dataList.push(item.fields);
                        }
                    });
                    this.dataList = dataList;
                });
            } else {
                if (!val.start || !val.end) {
                    this.$message({
                        message: '开始时间或者结束时间不能为空',
                        type: 'error'});
                    return;
                }
                if (!val.name) {
                    this.$message({
                        message: '名称不能为空',
                        type: 'error'});
                    return;
                }
                let time = dayjs(val.end).subtract(7, 'day');
                let is3Day = dayjs(val.start).isBefore(time, 'day');
                if (is3Day) {
                    this.$message({
                        message: '开始时间跟结束时间不能超过七天',
                        type: 'error'});
                    return;
                }
                let isBefore = dayjs(val.end).isBefore(val.start, 'day');
                if (isBefore) {
                    this.$message({
                        message: '结束时间不能小于开始时间',
                        type: 'error'});
                    return;
                }
                let startTime = dayjs(val.start).utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                let endTime = dayjs(val.end).utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                let params = {
                    size: 9999,
                    sort: [
                        {
                            _doc: {
                                order: 'desc',
                                unmapped_type: 'boolean'
                            }
                        }
                    ],
                    version: true,
                    fields: [
                        {
                            field: '*',
                            include_unmapped: 'true'
                        }
                    ],
                    script_fields: {

                    },
                    stored_fields: [
                        '*'
                    ],
                    runtime_mappings: {

                    },
                    _source: false,
                    query: {
                        bool: {
                            must: [

                            ],
                            filter: [
                                {
                                    bool: {
                                        should: [
                                            {
                                                match_phrase: {
                                                    'Identity.keyword': val.name
                                                }
                                            }
                                        ],
                                        minimum_should_match: 1
                                    }
                                },
                                {
                                    range: {
                                        Time: {
                                            gte: startTime,
                                            lte: endTime,
                                            format: 'strict_date_optional_time'
                                        }
                                    }
                                }
                            ],
                            should: [

                            ],
                            must_not: [

                            ]
                        }
                    }
                };
                this.$client.terminalEquipmentList(params).then(res => {
                    let dataList = [];
                    let data = res.data.hits.hits;
                    data.forEach(i => {
                        let item = this.equipmentList.filter(o => o.number === i.fields.Identity[0])[0];
                        if (i?.fields) {
                            for (let key in i.fields) {
                                i.fields[key] = i.fields[key][0];
                                i.fields.name = item.name;
                                i.fields.address = item.address;
                                i.fields.number = item.number;
                            }
                            dataList.push(i.fields);
                        }
                    });
                    this.dataList = dataList;
                });
            }
        },
    },
};
</script>
<style lang="less" scoped>
    @import '~@/assets/style/variables.less';

    .workflow-list {
        &__navbar {
            border-bottom: 1px solid @bg-border;
            padding-top: 10px;
            /deep/ .el-menu {
                background-color: transparent;
            }
            /deep/ .el-menu.el-menu--horizontal {
                height: 30px;
                border: none;
                margin-right: 0;
                margin-bottom: 0;
            }
            /deep/ .el-menu--horizontal > .el-menu-item {
                height: 35px;
                line-height: 35px;
            }
            /deep/ .el-header {
                padding: 0;
            }
            /deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
            /deep/ .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
            /deep/ .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
                background-color: lighten(@bg-dark, 22%);
            }
            /deep/ .el-menu--horizontal > .el-menu-item.is-active {
                border-color: #ededfd;
            }
        }
    }
</style>
